import { Paper, Grid, Box, Typography, Link, Stack } from "@mui/material";
import youtube from "../../Assets/youtube-footer-logo.png";
import instagram from "../../Assets/Instagram-footer-logo.png";
import facebook from "../../Assets/facebook-footer-logo.png";
import linkedin from "../../Assets/LinkedIN-footer-logo.png";

export const Footer = () => {
  return (
    <Paper
      sx={{
        mt: 8,
        bottom: 0,
        width: "100%",
        bgcolor: "primary.dark",
        borderRadius: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          py: 10,
          pl: { xs: 6, md: 0 },
          height: { xs: "464.48px", md: "272px" },
          width: { xs: "100%", md: "1104px" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            order: { xs: 3, md: 1 },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, color: "#FFFFFF" }}
          >
            © Copyright 2023 B120.eu
          </Typography>
          <Typography variant="caption">Minden jog fenntartva.</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            order: { xs: 1, md: 2 },
            display: "flex",
            mb: { md: 0, xs: 1 },
            justifyContent: { xs: "left", md: "end" },
          }}
        >
          <Box>
            <Link>Impresszum</Link>
            <br />
            <Link>Felhasználási feltételek</Link>
            <br />
            <Link>Adatvédelmi nyilatkozat</Link>
            <br />
            <Link>Süti szabályzat</Link>
            <br />
            <Link>Kapcsolat</Link>
            <br />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            order: { xs: 2, md: 3 },
            mb: { md: 0, xs: 6 },
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              justifyContent: { xs: "left", md: "end" },
            }}
          >
            <Box component="img" alt="background" src={youtube}></Box>
            <Box component="img" alt="background" src={instagram}></Box>
            <Box component="img" alt="background" src={facebook}></Box>
            <Box component="img" alt="background" src={linkedin}></Box>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
