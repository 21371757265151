import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";
import MessageIcon from "../../Assets/message-icon.png";
import ChatIcon from "../../Assets/chat-icon.png";
import TimecapsuleIcon from "../../Assets/timecapsule-icon.png";

export const GamificationCard = ({ icon, title, info, linkTo }) => {
  const handleOnClick = () => {
    console.log(linkTo);
  };
  return (
    <Card
      onlick={handleOnClick}
      sx={{
        border: "1px solid",
        borderColor: "#2B36741A",
        borderRadius: "16px",
        px: { xs: 1, sm: 2 },
        pt: { xs: 1, sm: 2 },
        pb: { xs: 1, sm: 1 },
        mt: 2,
      }}
    >
      <CardContent>
        <Stack
          direction={{ xs: "row", sm: "column", md: "row" }}
          spacing={2}
          alignItems={"center"}
        >
          <Box
            component="img"
            alt="icon"
            src={
              icon === "timecapsule"
                ? TimecapsuleIcon
                : icon === "message"
                ? MessageIcon
                : ChatIcon
            }
          ></Box>

          <Box sx={{ textAlign: "left" }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                mb: 1,
              }}
            >
              {title}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                mt: 1,
                color: "primary.main",
                lineHeight: "17.64px",
                fontWeight: 500,
              }}
            >
              {info}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
