import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";
import PropertyIcon from "../../Assets/property-icon.png";
import VehicleIcon from "../../Assets/vehicle-icon.png";
import BankAccountIcon from "../../Assets/bank-account-icon.png";
import PersonIcon from "../../Assets/person-icon.png";

export const DashboardCard = ({
  type,
  icon,
  title,
  info,
  currency = "",
  linkTo,
}) => {
  const formatValue = (price) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice;
  };
  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Box
                component="img"
                alt="icon"
                src={
                  icon === "property"
                    ? PropertyIcon
                    : icon === "vehicle"
                    ? VehicleIcon
                    : icon === "bankAccount"
                    ? BankAccountIcon
                    : PersonIcon
                }
              ></Box>

              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "20.16px",
                  }}
                >
                  {title}
                </Typography>

                <Typography
                  variant="body3"
                  sx={{
                    mt: 1,
                    fontSize: "14px",
                    lineHeight: "17.64px",
                  }}
                >
                  {type === "valuable"
                    ? `${formatValue(info)} ${currency}`
                    : info}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              href={linkTo}
              sx={{ minWidth: "44px", width: "44px", height: "44px" }}
            >
              <svg
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1.2998L9 9.7998L1 18.2998" stroke="#2B3674" />
              </svg>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
