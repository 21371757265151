import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const TotalValueCard = ({ price, currency }) => {
  const formatValue = (price) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice;
  };
  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        pb: 1,
        backgroundColor: "primary.main",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              sx={{
                lineHeight: "28px",
                color: "#FFFFFF",
              }}
            >
              Összes vagyontárgy
            </Typography>

            <Typography
              variant="h6"
              sx={{
                mt: 1,
                mb: 1,
                color: "#FFFFFF",
              }}
            >
              {`${formatValue(price)} ${currency}`}
            </Typography>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "14px",
                  lineHeight: "17.64px",
                  color: "#FFFFFF",
                }}
              >
                becsült érték
              </Typography>
              <Tooltip title="Lorem ipsum">
                <InfoOutlinedIcon
                  sx={{ width: "16px", height: "16px", color: "#FFFFFF" }}
                />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                backgroundColor: "#F8F8F8",
                p: 0,
                minWidth: "44px",
                width: "50px",
                height: "44px",
              }}
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.85355 21.3536C3.65829 21.5488 3.34171 21.5488 3.14645 21.3536C2.95118 21.1583 2.95118 20.8417 3.14645 20.6464L20.1464 3.64645C20.3417 3.45118 20.6583 3.45118 20.8536 3.64645C21.0488 3.84171 21.0488 4.15829 20.8536 4.35355L3.85355 21.3536ZM5.56743 16.0622C5.77394 16.2455 5.79272 16.5615 5.60938 16.7681C5.42605 16.9746 5.11002 16.9933 4.90352 16.81C3.63534 15.6841 2.65019 14.2704 2.03382 12.6808C1.98873 12.5645 1.98873 12.4355 2.03382 12.3192C4.09127 7.01317 9.94271 4.25672 15.3273 6.02527C15.5897 6.11143 15.7325 6.39397 15.6463 6.65632C15.5601 6.91868 15.2776 7.0615 15.0153 6.97533C10.1969 5.39276 4.96479 7.80886 3.03835 12.5C3.60037 13.8673 4.46614 15.0845 5.56743 16.0622ZM12.6197 8.54779C12.8926 8.59028 13.0793 8.84591 13.0369 9.11876C12.9944 9.39162 12.7387 9.57837 12.4659 9.53589C12.3129 9.51206 12.1573 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 12.6556 9.01181 12.8096 9.03513 12.961C9.07717 13.2339 8.88999 13.4893 8.61707 13.5313C8.34414 13.5733 8.08882 13.3862 8.04678 13.1132C8.01571 12.9115 8 12.7066 8 12.5C8 10.2909 9.79086 8.5 12 8.5C12.2088 8.5 12.4159 8.51605 12.6197 8.54779ZM11.0279 15.9004C10.9907 16.174 11.1823 16.426 11.4559 16.4632C11.6353 16.4877 11.817 16.5 12 16.5C14.2091 16.5 16 14.7091 16 12.5C16 12.3387 15.9904 12.1784 15.9714 12.0198C15.9386 11.7456 15.6897 11.55 15.4155 11.5828C15.1413 11.6157 14.9457 11.8646 14.9785 12.1387C14.9928 12.2579 15 12.3784 15 12.5C15 14.1569 13.6569 15.5 12 15.5C11.8621 15.5 11.7255 15.4907 11.5908 15.4724C11.3172 15.4351 11.0652 15.6267 11.0279 15.9004ZM18.3906 8.23195C18.2073 8.43845 18.2261 8.75448 18.4326 8.93781C19.5339 9.91555 20.3996 11.1327 20.9616 12.5C19.0352 17.1911 13.8031 19.6072 8.98475 18.0247C8.72239 17.9385 8.43986 18.0813 8.35369 18.3437C8.26752 18.606 8.41035 18.8886 8.6727 18.9747C14.0573 20.7433 19.9087 17.9868 21.9662 12.6808C22.0113 12.5645 22.0113 12.4355 21.9662 12.3192C21.3498 10.7296 20.3647 9.3159 19.0965 8.19C18.89 8.00667 18.5739 8.02545 18.3906 8.23195Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
