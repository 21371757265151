import React, { useEffect, useReducer, useState, Suspense, useRef } from 'react';
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import mobileBackground from "../Assets/daniel-j-schwarz-YtY724tdl7Y-unsplash 1.png";

import { useTranslation } from 'react-i18next';

import { useNavigate,useLocation } from "react-router-dom";


export default function ProfileMenu({globalEvent,setGlobalEvent,title}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	console.log('------ Global Event ------ ProfileMenu');
	console.log(globalEvent);
	console.log('------------');
	}
	
	if (globalEvent?.type == 'responseGetUser'){
	
	setUser(globalEvent?.data.user);
	
	}


  }, [globalEvent])


  const logOff = (event) => {
	setGlobalEvent({type:'doLogout',data:{}})
	handleClose(event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: { xs: "start", md: "end" },
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontWeight: 500,
            lineHeight: "14px",
            color: "primary.main",
          }}
        >
          Hello,
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            color: "primary.main",
          }}
        >
		{user?.firstName} {user?.lastName}
        </Typography>
      </Box>

      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          endIcon={
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.2327 13.494C20.5122 13.2544 20.9331 13.2868 21.1727 13.5663C21.4123 13.8459 21.3799 14.2667 21.1004 14.5063L16.4337 18.5063C16.1841 18.7203 15.8156 18.7203 15.566 18.5063L10.8993 14.5063C10.6198 14.2667 10.5874 13.8459 10.827 13.5663C11.0666 13.2868 11.4875 13.2544 11.767 13.494L15.9998 17.1221L20.2327 13.494Z"
                fill="#2B3674"
              />
            </svg>
          }
        >
          <Box
            component="img"
            alt="background"
            src={mobileBackground}
            sx={{
              width: 46,
              height: 46,
              borderRadius: "50%",
            }}
          ></Box>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={logOff}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
