import { createTheme } from "@mui/material";

export const CustomTheme = createTheme({
  palette: {
    primary: {
      main: "#2B3674",
      light: "#1542B9",
      dark: "#011638",
    },
    secondary: {
      main: "#FFF27E",
      light: "#E5FE4A",
    },
    info: {
      main: "#E9CCD0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "32px",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
        },
        contained: {
          borderRadius: "64px",
          backgroundColor: "#1542B9",
          textTransform: "none",
          lineHeight: "20px",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          boxShadow: "10px",
        },
        outlined: {
          borderWidth: "2px",
          borderColor: "#2B3674",
          borderRadius: "64px",
          textTransform: "none",
          lineHeight: "20px",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          boxShadow: "10px",
        },
        text: {
          "&:hover": { backgroundColor: "transparent", color: "#1542B9" },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 700,
          fontSize: "40px",
          lineHeight: "56px",
          color: "#2B3674",
          fontFamily: "Sora, sans-serif",
          xs: {
            fontWeight: 700,
            fontSize: "30px",
            lineHeight: "27.8px",
          },
        },
        body1: {
          fontSize: "24px",
          lineHeight: "32px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#2B3674",
        },
        body2: {
          fontSize: "16px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#2B3674",
          lineHeight: "20px",
        },
        caption: {
          fontSize: "12px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 500,
          color: "#FFFFFF",
          lineHeight: "15.12px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "primary.main",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#2B3674",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#2B3674",
          fontWeight: 500,
          fontFamily: "Sora, sans-serif",
          fontSize: "16px",
          lineHeight: "36px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#E9CCD0",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "16px",
          lineHeight: "32px",
        },
      },
    },
  },
});
