import logo from "../Assets/b120-logo.png";
import { Box, Button, Stack, Grid } from "@mui/material";
import ProfileMenu from "./ProfileMenu";

export const MainMenu = ({globalEvent,setGlobalEvent,title}) => {
  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 1,
        bgcolor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        borderBottom: 4,
        borderColor: "#EBEBEB",
      }}
    >
      <Grid
        container
        direction={"row"}
        sx={{
          width: { xs: "100%", xl: "1280px" },
          ml: { xs: 24, sm: 8, md: 12, xl: 24 },
          alignItems: "center",
        }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              my: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack spacing={{ xs: 1, sm: 2, xl: 4 }} direction={"row"}>
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <Button variant={"text"} href="#">
                Áttekintő
              </Button>
              <Button variant={"text"} href="#">
                Vagyonlista
              </Button>
              <Button variant={"text"} href="#">
                Kapcsolatok
              </Button>
              <Button variant={"text"} href="#">
                Rendelkezések
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              my: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <ProfileMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
