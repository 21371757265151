import { Box, Typography, Button } from "@mui/material";

export const ValidationStep2 = ({globalEvent,setGlobalEvent, title, text, link }) => {

  
  const doRegisterVerify = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'doRegisterVerify',data:data})
  };
  

  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mb: 6,
          p: 8,
          borderRadius: "100%",
          boxShadow: "0px 0px 10px 10px #0000001A",
        }}
      >
        <svg
          width="83"
          height="60"
          viewBox="0 0 83 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.75 50.9467L76.5983 1.09835C78.0628 -0.366116 80.4372 -0.366116 81.9017 1.09835C83.3661 2.56282 83.3661 4.93718 81.9017 6.40165L29.4016 58.9016C27.9372 60.3661 25.5628 60.3661 24.0984 58.9016L1.59835 36.4016C0.133884 34.9372 0.133884 32.5628 1.59835 31.0984C3.06282 29.6339 5.43718 29.6339 6.90165 31.0984L26.75 50.9467Z"
            fill="#62C275"
          />
        </svg>
      </Box>
      <Box sx={{ width: { sm: "250px", md: "500px" }, textAlign: "center" }}>
        <Typography variant="h6">{title}</Typography>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Typography variant="body3">{text}</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 3, width: { sm: "250px", md: "500px" } }}>
        <Button
          href={link}
          variant="contained"
          endIcon={
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7929 13H8C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12H17.7929L14.6464 8.85355C14.4512 8.65829 14.4512 8.34171 14.6464 8.14645C14.8417 7.95118 15.1583 7.95118 15.3536 8.14645L19.3536 12.1464C19.5488 12.3417 19.5488 12.6583 19.3536 12.8536L15.3536 16.8536C15.1583 17.0488 14.8417 17.0488 14.6464 16.8536C14.4512 16.6583 14.4512 16.3417 14.6464 16.1464L17.7929 13Z"
                fill="#E5FE4A"
              />
            </svg>
          }
          sx={{
            mt: 4,
            bgcolor: "primary.light",
            lineHeight: "32px",
            width: "100%",
          }}
		  onClick={(e)=>{doRegisterVerify(e,{})}}
		  
		>
          Tovább
        </Button>
      </Box>
    </Box>
  );
};
