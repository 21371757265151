import { MainMenu } from "../Navigation/MainMenu";
import { Typography, Grid, Stack, Box } from "@mui/material";
import { TotalValueCard } from "./TotalValueCard";
import { DashboardList } from "./DashboardList";
import valuablesCards from "./valuablesCardInfo.json";
import personCards from "./personCardInfo.json";
import { InfoCard } from "./InfoCard";
import { Footer } from "../Footer/Footer";
import { GamificationCard } from "./GamificationCard";

const Dashboard = ({globalEvent,setGlobalEvent,title}) => {
  return (
    <>
      <MainMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
      <Box sx={{ display: "flex", justifyContent: { xl: "center" } }}>
        <Grid
          sx={{
            mt: 8,
            pl: { xs: 2, sm: 27 },
            pr: { xs: 2, sm: 17 },
            mb: 20,
            width: { xl: "1530px" },
            minHeight: "100vh",
          }}
          container
        >
          <Grid item xs={12} sm={7}>
            <Typography variant="h6" sx={{ mb: 6 }}>
              Áttekintő
            </Typography>
            <TotalValueCard price={267000000} currency="HUF" />
            <DashboardList
              key="1"
              title="Vagyonlista (6)"
              linkTo="#"
              cards={valuablesCards}
            />
            <DashboardList
              key="2"
              title="Kedvezményezett személyek (2)"
              linkTo="#"
              cards={personCards}
            />
            <InfoCard />
          </Grid>
          <Grid item xs={12} sm={5} sx={{ pl: { xs: 0, sm: 6 }, mt: 11 }}>
            <Stack direction={"column"} spacing={2}>
              <GamificationCard
                icon="timecapsule"
                title="Időkapszula"
                info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt lab"
                linkTo="#"
              />
              <GamificationCard
                icon="chat"
                title="Kérdezz-felelek"
                info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt lab"
                linkTo="#"
              />
              <GamificationCard
                icon="message"
                title="Hagyj üzenetet!"
                info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt lab"
                linkTo="#"
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Dashboard;