import React, { useEffect, useReducer, useState, Suspense } from 'react';
import { Box, Typography, Button, Stack } from "@mui/material";
import { RegistrationButton } from "./RegistrationButton";

export const RegStep1 = ({globalEvent,setGlobalEvent}) => {
  
  useEffect(() => {
   window.AppleID.auth.init({
                clientId : 'hu.wst.b120.dev',
                scope : 'name email',
                redirectURI : 'https://b120-api.wst.hu',
                state : 'SignInUserAuthenticationRequest',
                nonce : String(Date.now()),
                usePopup : true
            });
  window.google.accounts.id.renderButton	  (
          document.getElementById("googleButtonDiv"),
          { theme: "outline", size: "large" }  
        );
  });
  
  const showRegStep2 = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showRegStep2',data:data})
  };

  
  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: { xs: "250px", md: "500px" } }}>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          Regisztráció email címmel
        </Typography>
        <Typography variant="body3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et
        </Typography>
      </Box>
      <Stack
              direction={{ xs: "column", md: "column" }}
              spacing={6}
              sx={{
                mt: { xs: 0, sm: 4 },
                width: { xs: "100%", sm: "auto" },
                justifyContent: "center",
              }}
            >
          <div>
		  <div id="appleid-signin"  className="signin-button" data-color="black" data-border="true" data-type="sign-in"></div>
		  </div>	  
		  <div>
		  <div id="googleButtonDiv"
             data-context="signin"
             data-ux_mode="popup"
             data-auto_prompt="false">
		  </div>
		</div>	 
		  <RegistrationButton bColor="main.light" onClick={(e)=>{showRegStep2(e,{})}} text="Regisztráció email címmel"/>
        </Stack>
      
      <Stack
        direction={"row"}
        sx={{
          mt: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" sx={{ lineHeight: "20.16px" }}>
          Már felhasználó vagyok!
        </Typography>
        <Button href="/login" sx={{ lineHeight: "20.16px" }}>
          Belépek
        </Button>
      </Stack>
    </Box>
  );
};
