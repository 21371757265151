import { React, useEffect, useReducer, useState }  from "react";
import logo from "../../Assets/b120-logo.png";
import { Box, Button, Stack, Grid } from "@mui/material";
import { LanguageSelector } from "../LanguageSelector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';

export const DesktopTopMenu = ({globalEvent,setGlobalEvent}) => {
	
  const { t, i18n } = useTranslation();	
	
  const [language, setLanguage] = useState(i18n.language);
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    
	i18n.changeLanguage(event.target.value, (err, t) => {
  if (err) return console.log('something went wrong loading', err);
  });
	
	console.log(language);
  };
  
 
  useEffect(() => {
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	console.log('------ Global Event ------ DesktopNavbar');
	console.log(globalEvent);
	console.log('------------');
	}
  }, [globalEvent])

 
 const navigate = useNavigate();
 
 const loginPage = () => {
        navigate("/login")
 }
 
 const registrationPage = () => {
        navigate("/registration")
 }
 
  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 1,
        bgcolor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        borderBottom: 4,
        borderColor: "#EBEBEB",
      }}
    >
      <Grid
        container
        direction={"row"}
        sx={{
          width: { xs: "100%", xl: "1280px" },
          ml: { xs: 24, sm: 8, md: 12, xl: 24 },
          alignItems: "center",
        }}
      >
        <Grid item xs={6}>
          <Box
            sx={{
              my: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack spacing={{ xs: 1, sm: 2, xl: 6 }} direction={"row"}>
              <div>
                <img src={logo} alt="Logo" />
              </div>
              <Button variant={"text"} href="#howitworks">
                {ReactHtmlParser (t('topmenu.howitworks'))}
              </Button>
              <Button variant={"text"} href="#whoisitfor">
                {ReactHtmlParser (t('topmenu.whoisitfor'))}
              </Button>
              <Button variant={"text"} href="#prices">
                {ReactHtmlParser (t('topmenu.prices'))}
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              my: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Stack spacing={{ xs: 1, sm: 2, xl: 6 }} direction={"row"}>
              <Button onClick={loginPage} variant={"text"}>
                {ReactHtmlParser (t('topmenu.login'))}
              </Button>
              <Button
                onClick={registrationPage} 
				variant={"contained"}
                sx={{ py: 2, px: 4 }}
              >
                {ReactHtmlParser (t('topmenu.registration'))}
              </Button>
              <LanguageSelector hasShadow={false} />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
