import { Box, Grid, Button } from "@mui/material";
import { LoginTopMenuLogout } from "../Navigation/LoginNavigation/LoginTopMenuLogout";
import registrationbg from "../Assets/Registration-bg.png";
import HomeComponent from '../HomePage/HomeComponent';
import Dashboard from '../Dashboard/Dashboard';

import { ValidationStep1 } from "../ValidationComponents/ValidationStep1";

import React, { useEffect, useReducer, useState, Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import { useNavigate,useLocation } from "react-router-dom";

const Landing = ({globalEvent,setGlobalEvent,title,cStep}) => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(null);
  
  const { t, i18n } = useTranslation();

  useEffect(() => {
  setGlobalEvent({type:'doGetUser',data:{}});
  },[])

  useEffect(() => {
  setGlobalEvent({type:'doGetUser',data:{}});
  },[location])

  useEffect(() => {
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	console.log('------ Global Event ------ Landing');
	console.log(globalEvent);
	console.log('------------');
	}
	
	if (globalEvent?.type == 'responseGetUser'){
	
	setUser(globalEvent?.data.user);
	console.log(globalEvent?.data.user);
	if (!!globalEvent?.data.user.emailVerified){ setVerified(true); }
	setChecked(true);
	
	}


  }, [globalEvent])

  useEffect(() => {
        setStep(cStep);
    }, [cStep])


  const goHome = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'goHome',data:data})
  };

  const showToast = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showToast',data:data})
  };

  const showConfirm = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showConfirm',data:data})
  };

  const showMessage = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showMessage',data:data})
  };

  
  
  const [step, setStep] = useState(cStep);

  const handleSetStep = () => {
    const newStep: number = step + 1;
    setStep(newStep);
  };

  return (
  
  <>
  {(localStorage.getItem("token") === null)&&(
  <HomeComponent globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Kezdőlap"}/>
  )}
  
  {(localStorage.getItem("token") !== null)&&(checked)&&(!verified)&&(
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid container sx={{}}>
        <Grid item md={6} sx={{ width: "50%" }}>
		<LoginTopMenuLogout />
        <ValidationStep1 globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
        </Grid>
        <Grid item md={6} sx={{ width: "50%" }}>
          <Box
            component="img"
            alt="background"
            src={registrationbg}
            sx={{
              top: 0,
              width: "100%",
              height: "100%",
            }}
          ></Box>
        </Grid>
      </Grid>
  </Box>)}
  
  {(localStorage.getItem("token") !== null)&&(checked)&&(verified)&&(
    
	<Dashboard globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Kezdőlap"}/>
	
	)}
  
  </>
  
  );
	
};


export default Landing;