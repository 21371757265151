import React, { useEffect, useReducer, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import authHeader from './AuthHeader';
import axios from 'axios';

const DataModule = ({globalEvent,setGlobalEvent}) => {

	const navigate = useNavigate();

	const [regData, setRegData] = useState();

	const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
	}


    
	const callAxios = (type,url,data,responseCode) => {

	let axiosinst = axios.create({
		baseURL: process.env.REACT_APP_BASE_URL,
		headers: authHeader()
	});
    
	if (type == 'get') {
	
	axiosinst.get(url,data).then(res => {setGlobalEvent({type:responseCode,data:res.data})}).catch((error
	) => {
    
		if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
			setGlobalEvent({type:responseCode+'Error',data:error.response.data})
		} else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
    });		
		
		
		
		
		
	} else {
	axiosinst.post(url,data).then(res => {setGlobalEvent({type:responseCode,data:res.data})}).catch((error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
			//let resData = error.response.data;
			//resData['postData'] = data; 
			//setGlobalEvent({type:responseCode+'Error',data:resData})
		} else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
    });
	
	};
	
	
	};
	
	useEffect(() => {
	
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	console.log('------ Global Event ------ DataModule');
	console.log(globalEvent);
	console.log('------------');
	}
	
	if (globalEvent?.type == 'goHome'){
		navigate('/');
	}

//ping	
	if (globalEvent?.type == 'doPing'){
		callAxios('get','/ping',globalEvent?.data,'responsePing')
		//axios.get(process.env.REACT_APP_BASE_URL+'/ping',{headers: authHeader()}).then(res => {setGlobalEvent({type:'showToast',data:{message:res.data}})})		
	}

	if (globalEvent?.type == 'responsePing') {
		
		if (globalEvent?.data.hasOwnProperty('message')) {
		
		setGlobalEvent({type:'showToast',data:globalEvent?.data});

		} else {

		setGlobalEvent({type:'showToast',data:{message:globalEvent?.data}})
			
		}
		
	}
	
	if (globalEvent?.type == 'responsePingError') {
		
		if (globalEvent?.data.hasOwnProperty('message')) {
		
		setGlobalEvent({type:'showToast',data:globalEvent?.data});

		} else {

		setGlobalEvent({type:'showToast',data:{message:globalEvent?.data}})
			
		}
		
	}

//register	
	
	if (globalEvent?.type == 'doRegister'){
	  
	  let sendData = globalEvent?.data;
	  
	  delete sendData.confirm_password;
	
	  setRegData(sendData);
	
	  navigate("/registration/consent");
	
	  //callAxios('post',process.env.REACT_APP_REGISTER_URL,sendData,'responseRegister')
	}
	
	if (globalEvent?.type == 'responseRegister'){
	  
	if (globalEvent?.data.hasOwnProperty('token')) {
		localStorage.setItem('token',globalEvent?.data.token);
		navigate('/registration/end', { replace: true });
	} else if (globalEvent?.data.hasOwnProperty('authorisation')) {

	if (globalEvent?.data.authorisation.hasOwnProperty('token')) {
		console.log(globalEvent?.data.authorisation.token);
		localStorage.setItem('token',globalEvent?.data.authorisation.token);	
		navigate('/registration/end', { replace: true });
	} else {
		setGlobalEvent({type:'showToast',data:{message:'Unexpected Error'}})}
	
	} else {
	  setGlobalEvent({type:'showToast',data:{message:'Unexpected Error'}})		
	}
	
	}
	
	if (globalEvent?.type == 'doRegisterVerify'){
		navigate('/registration/verify', { replace: true });
	}

	if (globalEvent?.type == 'doRegisterConsent'){

	  callAxios('post',process.env.REACT_APP_REGISTER_URL,regData,'responseRegister')

	}

//login

	if (globalEvent?.type == 'doLogin'){
	  callAxios('post',process.env.REACT_APP_LOGIN_URL,globalEvent?.data,'responseLogin')
	}
	
	if (globalEvent?.type == 'responseLogin'){
	  
	if (globalEvent?.data.hasOwnProperty('token')) {
		localStorage.setItem('token',globalEvent?.data.token);
		navigate('/', { replace: true });
	} else if (globalEvent?.data.hasOwnProperty('authorisation')) {

	if (globalEvent?.data.authorisation.hasOwnProperty('token')) {
		console.log(globalEvent?.data.authorisation.token);
		localStorage.setItem('token',globalEvent?.data.authorisation.token);	
		navigate('/', { replace: true });
	} else {
		setGlobalEvent({type:'showToast',data:{message:'Unexpected Error'}})}
	
	} else {
	  setGlobalEvent({type:'showToast',data:{message:'Unexpected Error'}})		
	}
	}
	

//login

	if (globalEvent?.type == 'doLogout'){
	  localStorage.removeItem('token');
	  navigate('/', { replace: true });
	}


//getUser

	if (globalEvent?.type == 'doGetUser'){
	  callAxios('get','/users/current',globalEvent?.data,'responseGetUser')
	}
	
	
	}, [globalEvent])
    
  return (
    <></>
  );
};

export default DataModule;