import AuthNavigator from '../AuthNavigator/AuthNavigator';
import React, { useEffect, useReducer, useState } from 'react';
import { ThemeProvider } from "@mui/material";
import { CustomTheme } from "./Theme";


class App extends React.Component {
  
  componentDidMount() {
    //google login
	const gscript = document.createElement("script");
    gscript.src = "https://accounts.google.com/gsi/client";
    gscript.async = true;
    document.body.appendChild(gscript);
	gscript.onload = (evt) => {
	
		window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: (response) => {

		  localStorage.setItem("jwt", response.credential);
			  
		  let xhr = new XMLHttpRequest();

		  let json = JSON.stringify({
				jwt: response.credential
		  });

		  xhr.onloadend = function() {
			
			if (xhr.status == 200) {

				console.log("google success - local");
				console.log(xhr.response);
				var jsonResponse = JSON.parse(xhr.response);
				localStorage.setItem("token", jsonResponse.authorisation.token);
				document.location.href="/"

			} if (xhr.status == 201) {

				console.log("google success - remote");
				console.log(xhr.response);
				var jsonResponse = JSON.parse(xhr.response);
				localStorage.setItem("token", jsonResponse.token);
				document.location.href="/"

			} else {
				console.log("google error " + this.status);
			}
		  };

		  xhr.open("POST", process.env.REACT_APP_BASE_URL+'/tokens/google')
		  xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
		  xhr.send(json); 	  
			  
		  }
        });
		
	}
  
  
  //apple login
	const ascript = document.createElement("script");
    ascript.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    ascript.async = true;
    document.body.appendChild(ascript);
	ascript.onload = (evt) => {
		
			 window.AppleID.auth.init({
                clientId : 'hu.wst.b120.dev',
                scope : 'name email',
                redirectURI : window.location.origin,
                state : 'SignInUserAuthenticationRequest',
                nonce : String(Date.now()),
                usePopup : true
            });
	
	}
  
  
  
  
  
  
  
  }

  componentWillUnmount() {
    
  }
  
  render() {
  
  return (
    <>
	<ThemeProvider theme={CustomTheme}>
      <AuthNavigator/>
	</ThemeProvider>
    </>
  );
  
  }
}

export default App;