import React, { useEffect, useReducer, useState } from 'react';

import {
  BrowserRouter,
  Route,
  Routes,
  useHistory,
  useLocation,
  useNavigate
} from 'react-router-dom';

import PopupHandler from '../PopupHandler/PopupHandler';
import HomeComponent from '../HomePage/HomeComponent';
import Login from '../Login/Login';
import Registration from '../Registration/Registration';
import Dashboard from '../Dashboard/Dashboard';
import Landing from '../Landing/Landing';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

import DataModule from '../DataModule/DataModule';

const AuthNavigator = () => {


  //const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  //const bhistory = useNavigate(/*{basename: baseUrl}*/);



  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null); 
  const [globalEvent, setGlobalEvent] = useState(null);
  const fireGlobalEvent = (evnt) => {
	console.log(evnt);
	setGlobalEvent(evnt);  
  }
  
  
  useEffect(() => {
      if (initializing) {
        setInitializing(false);
      }
  }, []);

  if (initializing) return 'Loading....';

  return (
	<>
    <BrowserRouter>
	<DataModule globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}/>
	<Routes>
	{/*
        <Route path="/"  element={<HomeComponent globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Kezdőlap"}/>} />
	*/}	
		<Route path="/login"  element={<Login globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Bejelentkezés"}/>} />
		
		<Route path="/registration"  element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={1}/>} />
    
		<Route path="/registration/email"  element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={2}/>} />
	
		<Route path="/registration/consent"  element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={4}/>} />
	
		<Route path="/registration/verify"  element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={3}/>} />
	
		<Route path="/registration/end"  element={<Registration globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={"Regisztráció"} cStep={5}/>} />
	
		<Route path="/forgotpassword"  element={<ForgotPassword globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>} />
	
		<Route path="/dashboard"  element={<Dashboard globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>} />

		<Route path="/"  element={<Landing globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} title={""}/>} />
	
	</Routes>
	</BrowserRouter>
	<PopupHandler globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}/>
	
	</>
  );
}

export default AuthNavigator;