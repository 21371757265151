import {
  Box,
  Typography,
  Button,
  Stack,
  TextField,
  FormControl,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

export const ValidationStep1 = ({globalEvent,setGlobalEvent}) => {

  const pinLength = 6;
  const [pin, setPin] = useState(new Array(pinLength).fill(""));
  const [activePin, setActivePin] = useState(0);
  const inputRef = useRef(null);

  const handleOnchange = ({ target }, index) => {
    const { value } = target;
    const newPin = [...pin];
    newPin[index] = value;

    if (value) {
      setActivePin(index + 1);
    }

    setPin(newPin);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (pin[activePin] === "") {
        setActivePin(index - 1);
      } else {
        setActivePin(index);
      }
    }
    if (e.ctrlKey && (e.key === "v" || e.key === "V")) {
      navigator.clipboard.readText().then((cliptext) => {
        if (cliptext.length >= pinLength) {
          setPin(cliptext.substring(0, pinLength).split(""));
        } else {
          while (cliptext.length < pinLength) {
            cliptext += " ";
          }
          setPin(cliptext.substring(0, pinLength).split(""));
        }
      });
      setActivePin(3);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activePin]);

  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: { sm: "250px", md: "500px" } }}>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          Írd be a megerősítő kódot!
        </Typography>
        <Typography variant="body3">
          A jóváhagyáshoz szükséges kódot emailben elküldtük.
        </Typography>
      </Box>
      <FormControl
        variant="standard"
        sx={{ mt: 3, width: { sm: "250px", md: "500px" } }}
      >
        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
          {pin.map((_, key) => {
            return (
              <TextField
                key={key}
                inputProps={{
                  maxLength: 1,
                  focus: key === activePin ? "true" : "false",
                  autoFocus: key === activePin ? true : false,
                  ref: key === activePin ? inputRef : null,
                }}
                onChange={(e) => handleOnchange(e, key)}
                onKeyDown={(e) => handleKeyDown(e, key)}
                label=""
                required
                value={pin[key]}
                sx={{
                  "& .MuiInputBase-root": {
                    textAlign: "center",
                    width: "78.43px",
                    height: "108px",
                    border: "2px",
                    borderColor: "#7C7C7C",
                    fontSize: "56px",
                    lineHeight: "80px",
                    color: "primary.main",
                    paddingLeft: 0,
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
              />
            );
          })}
        </Stack>
      </FormControl>
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{ mt: 4, alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="body2" sx={{ lineHeight: "20.16px" }}>
          Nem kaptam meg a kódot!
        </Typography>
        <Button href="#" sx={{ lineHeight: "20.16px" }}>
          Újraküldés!
        </Button>
      </Stack>
    </Box>
  );
};
