import React, { useEffect, useReducer, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ReactHtmlParser from 'html-react-parser'; 
 
const PopupHandler = ({globalEvent,setGlobalEvent,title}) => {

  const [callroot, setCallRoot] = useState('');
  const [calldata, setCallData] = useState('');
  
  const [message, setMessage] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  
  
  const [snackOpen, setSnackOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);

  const [vertical, setVertial] = useState('top');;
  const [horizontal, setHorizontal] = useState('center');;
  


const confirmStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px', 
  boxShadow: 24,
  p: 4,
  display: 'flex-column',
  justifyContent: 'center',
  alignItems: 'center'
};

const messageStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px', 
  boxShadow: 24,
  p: 4,
  display: 'flex-column',
  justifyContent: 'center',
  alignItems: 'center'
};


  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);
  const handleMessageOpen = () => setMessageOpen(true);
  const handleMessageClose = () => setMessageOpen(false);

  const handleSnackClose = () => setSnackOpen(false);

  useEffect(() => {
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	console.log('------ Global Event ------ PopupHandler');
	console.log(globalEvent);
	console.log('------------');
	}
	
	if (globalEvent?.type == 'showToast'){
		setMessage(globalEvent?.data?.message);
		setSnackOpen(true);
	}

	if (globalEvent?.type == 'showConfirm'){
		setMessage(globalEvent?.data?.message);
		setCallRoot(globalEvent?.data?.callroot);
		setCallData(globalEvent?.data);
		handleConfirmOpen();
	}


	if (globalEvent?.type == 'showMessage'){
		setMessage(globalEvent?.data?.message);
		setCallRoot(globalEvent?.data?.callroot);
		setCallData(globalEvent?.data);
		handleMessageOpen();
	}

  }, [globalEvent])
  

return (
	<>
	
		<Snackbar
		ContentProps={{
			sx: {
				backgroundColor: 'primary.main'
			}
		}}
		anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        message={message}
		onClose={handleSnackClose}
		key={vertical + horizontal}
		/>


<Grid
  container
  direction="row"
  justifyContent="space-between"
  alignItems="center"
>
    <Grid item></Grid>
    <Grid item></Grid>
</Grid>


		<Modal open={confirmOpen} onClose={handleConfirmClose}>
			<Box sx={confirmStyle} >
				<div sx={{flexGrow:1, textAlign: 'center', width: '100%'}}>
				<CheckIcon sx={{display: 'block', margin: '0 auto', fontSize: '2em', color: 'green', backgroundColor: '#f0f0f0', borderRadius: '2em', padding: '1.2em'}} />
				</div>
			
				<div className={'message'} style={{flexGrow:1, padding: '3rem'}}>
				
				        <Typography
          variant="h5"
          sx={{
            mb: 3,
            fontSize: { xs: "1.5em", md: "1.5em" },
			color: '#2B3674'
          }}
        >
          {message}
        </Typography>
				{ReactHtmlParser (message)}
				
				</div>
				<div style={{display: 'flex', flexGrow:1, justifyContent:'space-evenly'}}>
					<Button sx={{
                  m: 2,
                  py: 2,
                  px: 4,
                  borderRadius: '2em',
				  width: "100%",
                  textAlign: "center",
                  bgcolor: "transparent",
                  color: "primary.main",
                  "&:hover": { backgroundColor: "primary.light" },
                }} onClick={()=>{setAnswer(false);setGlobalEvent({type:'confirmAnswered',data:{answer:false,callroot:callroot,calldata:calldata}});handleConfirmClose()}}>mégsem</Button>
					<Button sx={{
                  m: 2,
                  py: 2,
                  px: 4,
				  borderRadius: '2em',
                  width: "100%",
                  textAlign: "center",
                  bgcolor: "primary.main",
                  color: "white",
                  "&:hover": { backgroundColor: "primary.light" },
                }} onClick={()=>{setAnswer(true);setGlobalEvent({type:'confirmAnswered',data:{answer:true,callroot:callroot,calldata:calldata}});handleConfirmClose()}}>igen</Button>
					
				</div>
			</Box>
	    </Modal>
		
		
		<Modal open={messageOpen} onClose={handleMessageClose}>
			<>
			<Box sx={messageStyle} >
				<div sx={{flexGrow:1, textAlign: 'center', width: '100%'}}>
				<CheckIcon sx={{display: 'block', margin: '0 auto', fontSize: '2em', color: 'green', backgroundColor: '#f0f0f0', borderRadius: '2em', padding: '1.2em'}} />
				</div>
			
				<div className={'message'} style={{flexGrow:1, padding: '3rem'}}>
				
				<Typography
          variant="h5"
          sx={{
            mb: 3,
            fontSize: { xs: "1.5em", md: "1.5em" },
			color: '#2B3674'
          }}
        >
          {message}
        </Typography>
				
				{ReactHtmlParser (message)}</div>
				
				<div style={{display: 'flex', flexGrow:1, justifyContent:'space-evenly'}}>
					<Button sx={{
                  m: 2,
                  py: 2,
                  px: 4,
				  borderRadius: '2em',
                  width: "100%",
                  textAlign: "center",
                  bgcolor: "primary.main",
                  color: "white",
                  "&:hover": { backgroundColor: "primary.light" },
                }}  onClick={()=>{setGlobalEvent({type:'messageClosed',data:{callroot:callroot,calldata:calldata}});handleMessageClose()}}>bezárás</Button>
				</div>
			</Box>
	    </>
		</Modal>
		
	</>
);
}

export default PopupHandler;