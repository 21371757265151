import { Card, CardContent, Typography, Stack, Button } from "@mui/material";

export const InfoCard = () => {
  return (
    <Card
      sx={{
        borderRadius: "32px",
        px: { xs: 2, sm: 5 },
        py: { xs: 2, sm: 5 },
        bgcolor: "#E9CCD0",
      }}
    >
      <CardContent>
        <Typography
          variant="subtitle1"
          sx={{
            lineHeight: "25px",
            fontWeight: 600,
            mb: 4,
          }}
        >
          Biztonságban vannak az adataim?
        </Typography>

        <svg
          width="175"
          height="14"
          viewBox="0 0 175 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2_14974)">
            <path
              d="M38.0874 3.24652C54.7105 2.86577 71.3427 2.621 87.9749 2.51221C104.607 2.40342 121.085 2.43968 137.636 2.61193C147.038 2.71165 156.449 2.84763 165.851 3.02895L167.127 1.17051C145.663 1.5422 124.207 2.00454 102.752 2.55754C81.2967 3.11054 59.8414 3.75419 38.386 4.49756C26.3417 4.91458 14.3064 5.34973 2.26211 5.82114C1.55628 5.84833 0.49754 6.02964 0.0993808 6.71862C-0.271631 7.36228 0.416098 7.6977 0.98619 7.67957C23.3464 7.10844 45.7248 7.05405 68.0941 7.52546C90.4634 7.99687 112.815 9.00314 135.139 10.5262C147.681 11.3783 160.214 12.4027 172.729 13.5903C173.407 13.6538 174.529 13.3183 174.891 12.6928C175.289 12.0129 174.547 11.7772 174.004 11.7319C151.735 9.6196 129.411 8.02406 107.068 6.95433C84.7263 5.88459 62.3479 5.34973 39.9786 5.34066C27.4095 5.34066 14.8403 5.49478 2.27116 5.82114L0.995239 7.67957C22.4415 6.83648 43.8969 6.0931 65.3522 5.43132C86.8076 4.7786 108.263 4.20747 129.718 3.73606C141.763 3.47316 153.816 3.22839 165.86 3.01988C166.566 3.01081 167.634 2.80231 168.023 2.12239C168.412 1.44247 167.697 1.17051 167.136 1.16144C150.495 0.853213 133.872 0.662837 117.24 0.617509C100.607 0.572181 84.1291 0.653771 67.5783 0.88041C58.1763 1.00733 48.7653 1.17957 39.3633 1.39715C38.6575 1.41528 37.5897 1.61472 37.2006 2.29464C36.8296 2.93829 37.5173 3.27372 38.0874 3.25558V3.24652Z"
              fill="#2B3674"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_14974">
              <rect
                width="175"
                height="13"
                fill="white"
                transform="translate(0 0.599609)"
              />
            </clipPath>
          </defs>
        </svg>

        <Typography
          variant="body2"
          sx={{
            mt: 4,
            mb: 4,
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis
        </Typography>
        <Stack direction={"row"} spacing={0}>
          <Button variant="text" sx={{ minWidth: "5px" }}>
            <svg
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-0.00129818 3.72967C-0.00129818 2.00509 1.46938 0.602356 3.27686 0.602356C5.08434 0.602356 6.55501 2.00534 6.55501 3.72967C6.55501 5.45403 5.08464 6.85726 3.27686 6.85726C1.46938 6.85726 -0.00129818 5.45428 -0.00129818 3.72967Z"
                fill="#2B3674"
              />
            </svg>
          </Button>
          <Button variant="text" sx={{ minWidth: "5px" }}>
            <svg
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.552413 3.72967C0.552413 2.00509 2.02309 0.602356 3.83057 0.602356C5.63805 0.602356 7.10873 2.00534 7.10873 3.72967C7.10873 5.45403 5.63835 6.85726 3.83057 6.85726C2.02309 6.85726 0.552413 5.45428 0.552413 3.72967Z"
                fill="white"
              />
            </svg>
          </Button>
          <Button variant="text" sx={{ minWidth: "5px" }}>
            <svg
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.552413 3.72967C0.552413 2.00509 2.02309 0.602356 3.83057 0.602356C5.63805 0.602356 7.10873 2.00534 7.10873 3.72967C7.10873 5.45403 5.63835 6.85726 3.83057 6.85726C2.02309 6.85726 0.552413 5.45428 0.552413 3.72967Z"
                fill="white"
              />
            </svg>
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
