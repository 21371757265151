import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  Stack,
  Button,
} from "@mui/material";


import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';


export const PricesCardComponent = (pricesData: any) => {
  
  const { t, i18n } = useTranslation();	

  const price: number = pricesData.pricesData.price;

  const formatPrice = (price: number) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice;
  };

  return (
    <>
      <Card
        sx={{
          width: { xs: "352px", md: "368px" },
          position: "relative",
          zIndex: pricesData.pricesData.popular ? 1 : 0,
          borderRadius: "16px",
          boxShadow: 10,
          mt: { xs: 4, md: 0 },
        }}
      >
        {pricesData.pricesData.popular ? (
          <Box
            sx={{
              bgcolor: "primary.main",
              color: "secondary.main",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "44px",
            }}
          >
            <Typography
              sx={{
                color: "secondary.main",
                fontWeight: 800,
              }}
            >
              NÉPSZERŰ
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <CardContent
          sx={{
            pt: 8,
            pb: 6,
            height: pricesData.pricesData.popular
              ? { xs: "605px", md: "650px" }
              : { xs: "469px", md: "606px" },
          }}
        >
          <Box sx={{ pl: 8 }}>
            <Typography sx={{ fontWeight: 800 }}>
              {pricesData.pricesData.title}
            </Typography>
            <Stack direction={"row"} spacing={2} sx={{ alignItems: "end" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 800, lineHeight: "50.4px" }}
              >
                {formatPrice(price)}
              </Typography>
              <Typography sx={{ fontWeight: 800, lineHeight: "40px" }}>
                {" "}
                {pricesData.pricesData.currency}
              </Typography>
            </Stack>
          </Box>

          <List sx={{ listStyleType: "disc", height: "386px", px: 4, mt: 5 }}>
            {Object.keys(pricesData.pricesData.description).map(
              (desc: string) => {
                return (
                  <ListItem
                    key={desc}
                    sx={{
                      display: "list-item",
                      pl: 1,
                      py: pricesData.pricesData.title === "Prémium" ? 0 : 1,
                      lineHeight: "24px",
                    }}
                  >
                    {pricesData.pricesData.description[desc]}
                  </ListItem>
                );
              }
            )}
          </List>
          <Box sx={{ display: "flex", justifyContent: "center", px: 4 }}>
            {pricesData.pricesData.popular ? (
              <Button
                sx={{
                  mb: 2,
                  py: 2,
                  px: 4,
                  width: "100%",
                  textAlign: "center",
                  bgcolor: "secondary.main",
                  color: "primary.main",
                  "&:hover": { backgroundColor: "secondary.light" },
                }}
                endIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.2929 13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12H17.2929L14.1464 8.85355C13.9512 8.65829 13.9512 8.34171 14.1464 8.14645C14.3417 7.95118 14.6583 7.95118 14.8536 8.14645L18.8536 12.1464C19.0488 12.3417 19.0488 12.6583 18.8536 12.8536L14.8536 16.8536C14.6583 17.0488 14.3417 17.0488 14.1464 16.8536C13.9512 16.6583 13.9512 16.3417 14.1464 16.1464L17.2929 13Z"
                      fill="#1542B9"
                    />
                  </svg>
                }
                variant={"contained"}
              >
                {ReactHtmlParser (t('common.selectit'))}
              </Button>
            ) : (
              <Button
                sx={{
                  mb: 2,
                  py: 2,
                  px: 4,
                  width: "100%",
                  textAlign: "center",
                }}
                endIcon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.2929 13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12H17.2929L14.1464 8.85355C13.9512 8.65829 13.9512 8.34171 14.1464 8.14645C14.3417 7.95118 14.6583 7.95118 14.8536 8.14645L18.8536 12.1464C19.0488 12.3417 19.0488 12.6583 18.8536 12.8536L14.8536 16.8536C14.6583 17.0488 14.3417 17.0488 14.1464 16.8536C13.9512 16.6583 13.9512 16.3417 14.1464 16.1464L17.2929 13Z"
                      fill="#1542B9"
                    />
                  </svg>
                }
                variant={"outlined"}
              >
                {ReactHtmlParser (t('common.selectit'))}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
