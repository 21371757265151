import React, { useEffect, useReducer, useState, Suspense } from 'react';

import { LoginTopMenu } from "../Navigation/LoginNavigation/LoginTopMenu";
import loginbg from "../Assets/login-background.png";
import { LoginField } from "../Commons/LoginField";
import { Footer } from "../Footer/Footer";

import EyeIcon from '@mui/icons-material/VisibilityOutlined';
import EyeOffIcon from '@mui/icons-material/VisibilityOffOutlined';

import { useForm } from "react-hook-form"

import {
  Box,
  Button,
  Stack,
  TextField,
  MenuItem,
  InputAdornment,
  Grid,
  Typography,
  IconButton
} from "@mui/material";


import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';

export const RegStep2 = ({globalEvent,setGlobalEvent}) => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,watch, setValue
  } = useForm({
    mode: "onBlur"
  });


  const onSubmit = (data, e) => {doRegister(e,data); console.log(data, e)}
  const onError = (errors, e) => {console.log(errors, e)}


  const [password, setPassword] = useState("");
  const [type, setType] = useState('password');
  const [visibility, setVisibility] = useState(true);

  const { t, i18n } = useTranslation();	
  const [language, setLanguage] = useState(i18n.language);
  
    const doRegister = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'doRegister',data:data})
  };
  
  useEffect(() => {
	console.log('------ Global Event ------ RegForm');
	console.log(globalEvent);
	console.log('------------');

	if (globalEvent?.type == 'showRegisterError'){


	if (globalEvent?.data.hasOwnProperty('postData')) {
		 
		 console.log(globalEvent?.data.postData.email)
		 
		 setValue("email", globalEvent?.data.postData.email, {
            shouldValidate: true,
            shouldDirty: true
          })
		 
		  	
		}
	
	if (globalEvent?.data.hasOwnProperty('errors')) {
	  setGlobalEvent({type:'showToast',data:{'message':t('errors.login.'+globalEvent?.data.errors[0].code)}});
	} else if (globalEvent?.data.hasOwnProperty('message')) {
	  setGlobalEvent({type:'showToast',data:globalEvent?.data});
	
	
	} else {
	setGlobalEvent({type:'showToast',data:{message: t('errors.login.register')}})
	}
	
	}
	
	


  }, [globalEvent])
  
  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: { sm: "250px", md: "500px" } }}>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Regisztráció email címmel
        </Typography>
        <Typography variant="body3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et
        </Typography>
      </Box>
      <Box sx={{ mt: 3, width: { sm: "250px", md: "500px" } }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
          <div>
           <TextField key="email" label={ReactHtmlParser (t('field.email'))} type="email" 
				  
{...register("email", {
          required: "required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        })}
				  sx={{
        mt: 3,
        width: "100%",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20.16px",
        "& .MuiInputBase-root": { borderRadius: "50px" },
      }}
				  />
				  
				  {errors.email && <span role="alert">{errors.email.message}</span>}
				  
                  <TextField key="password" label={ReactHtmlParser (t('field.password'))} type={type}
				  {...register("password", {
					required: "required",
				  })}
				  
				  sx={{
        mt: 3,
        width: "100%",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20.16px",
        "& .MuiInputBase-root": { borderRadius: "50px" },
      }}
				  InputProps={{ 
                    endAdornment: <InputAdornment position="end"> 
                       <IconButton onClick={(e) => {setType(visibility?'text':'password'); setVisibility(!visibility)}}> 
                            {visibility ? <EyeIcon/>  : <EyeOffIcon/> } 
                        </IconButton> 
                    </InputAdornment>, 
                }}
				  />
				  {errors.password && <span role="alert">{errors.password.message}</span>}
            <TextField key="confirm_password" label={ReactHtmlParser (t('field.password'))} type={type}
				  {...register("confirm_password", {
					required: "required",
  validate: (val: string) => {
    if (watch('password') != val) {
      return "Your passwords does not match";
    }
  },
				  })}
				  
				  sx={{
        mt: 3,
        width: "100%",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20.16px",
        "& .MuiInputBase-root": { borderRadius: "50px" },
      }}
				  InputProps={{ 
                    endAdornment: <InputAdornment position="end"> 
                       <IconButton onClick={(e) => {setType(visibility?'text':'password'); setVisibility(!visibility)}}> 
                            {visibility ? <EyeIcon/>  : <EyeOffIcon/> } 
                        </IconButton> 
                    </InputAdornment>, 
                }}
				  />
				  {errors.confirm_password && <span role="alert">{errors.confirm_password.message}</span>}
            <Button
              type="submit"
              variant="contained"
              endIcon={
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7929 13H8C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12H17.7929L14.6464 8.85355C14.4512 8.65829 14.4512 8.34171 14.6464 8.14645C14.8417 7.95118 15.1583 7.95118 15.3536 8.14645L19.3536 12.1464C19.5488 12.3417 19.5488 12.6583 19.3536 12.8536L15.3536 16.8536C15.1583 17.0488 14.8417 17.0488 14.6464 16.8536C14.4512 16.6583 14.4512 16.3417 14.6464 16.1464L17.7929 13Z"
                    fill="#E5FE4A"
                  />
                </svg>
              }
              sx={{
                mt: 4,
                bgcolor: "primary.light",
                lineHeight: "32px",
                width: "100%",
              }}
            >
              Regisztrálok
            </Button>
          </div>
        </Box>
      </Box>
      <Stack
        direction={"row"}
        sx={{ mt: 4, alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="body2" sx={{ lineHeight: "20.16px" }}>
          Már felhasználó vagyok!
        </Typography>
        <Button href="/login" sx={{ lineHeight: "20.16px" }}>
          Belépek
        </Button>
      </Stack>
    </Box>
  );
};
