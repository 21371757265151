import { DashboardCard } from "./DashboardCard";
import { Button, Typography, Stack, Box } from "@mui/material";

export const DashboardList = ({ title, linkTo, cards }) => {
  return (
    <Box sx={{ my: 4 }}>
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 3 }}>
        <Typography
          variant="subtitle2"
          sx={{ mb: 2, color: "primary.main", fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Button
          variant={"text"}
          href={linkTo}
          endIcon={
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2929 5.43327H0.5C0.223858 5.43327 0 5.20195 0 4.9166C0 4.63125 0.223858 4.39993 0.5 4.39993H10.2929L7.14645 1.14861C6.95118 0.946836 6.95118 0.619701 7.14645 0.41793C7.34171 0.216159 7.65829 0.216159 7.85355 0.41793L11.8536 4.55126C12.0488 4.75303 12.0488 5.08017 11.8536 5.28194L7.85355 9.41527C7.65829 9.61704 7.34171 9.61704 7.14645 9.41527C6.95118 9.2135 6.95118 8.88637 7.14645 8.6846L10.2929 5.43327Z"
                fill="#EB8176"
              />
            </svg>
          }
          sx={{ fontSize: "14px", lineHeight: "17.64px" }}
        >
          összes
        </Button>
      </Stack>
      {cards.map((card) => {
        return (
          <DashboardCard
            key={card.key}
            type={card.type}
            icon={card.icon}
            title={card.title}
            info={card.info}
            currency={card.currency}
            linTo={card.linkTo}
          />
        );
      })}
      <Stack justifyContent={"center"} sx={{ mt: 2 }}>
        <Button
          variant={"text"}
          startIcon={
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5 7.7998H18V5.2998C18 5.02366 17.7761 4.7998 17.5 4.7998C17.2239 4.7998 17 5.02366 17 5.2998V7.7998H14.5C14.2239 7.7998 14 8.02366 14 8.2998C14 8.57595 14.2239 8.7998 14.5 8.7998H17V11.2998C17 11.5759 17.2239 11.7998 17.5 11.7998C17.7761 11.7998 18 11.5759 18 11.2998V8.7998H20.5C20.7761 8.7998 21 8.57595 21 8.2998C21 8.02366 20.7761 7.7998 20.5 7.7998ZM12 8.2998C12 8.02366 11.7761 7.7998 11.5 7.7998H3.5C3.22386 7.7998 3 8.02366 3 8.2998C3 8.57595 3.22386 8.7998 3.5 8.7998H11.5C11.7761 8.7998 12 8.57595 12 8.2998ZM14.5 12.7998C14.7761 12.7998 15 13.0237 15 13.2998C15 13.5759 14.7761 13.7998 14.5 13.7998H3.5C3.22386 13.7998 3 13.5759 3 13.2998C3 13.0237 3.22386 12.7998 3.5 12.7998H14.5ZM21 18.2998C21 18.0237 20.7761 17.7998 20.5 17.7998H3.5C3.22386 17.7998 3 18.0237 3 18.2998C3 18.5759 3.22386 18.7998 3.5 18.7998H20.5C20.7761 18.7998 21 18.5759 21 18.2998Z"
                fill="#2B3674"
              />
            </svg>
          }
        >
          További vagyontárgy felvitele
        </Button>
      </Stack>
    </Box>
  );
};
