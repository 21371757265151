import { useState } from "react";
import b120Text from "../../Assets/b120-text.png";
import lifetresor from "../../Assets/the-LIFETRESOR.png";
import {
  Box,
  Button,
  Stack,
  TextField,
  MenuItem,
  Menu,
  InputAdornment,
  Link,
} from "@mui/material";

export const MobileTopMenu = () => {
  const [language, setLanguage] = useState("hu");
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    console.log(language);
  };
  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "relative",
          zIndex: 1,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          mt: 6,
          mb: 22,
          py: 2,
          px: 2,
          alignItems: "center",
        }}
      >
        <Box component="img" alt="background" src={b120Text}></Box>
        <Box component="img" alt="background" src={lifetresor}></Box>
        <Button sx={{ ml: 8 }} onClick={handleToggleMenu}>
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="46" height="46" rx="23" fill="#2B3674" />
            <line x1="13" y1="15.5" x2="32" y2="15.5" stroke="white" />
            <line x1="13" y1="22.5" x2="32" y2="22.5" stroke="white" />
            <line x1="13" y1="29.5" x2="32" y2="29.5" stroke="white" />
          </svg>
        </Button>
      </Stack>
      <Menu
        sx={{
          bgcolor: "primary.dark",
          boxShadow: "0px",
          border: "0px",
          backgroundColor: "transparent",
          width: "100%",
          height: "100%",
        }}
        open={toggleMenu}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ pr: 2, pl: 3, mb: 4 }}
        >
          <Box width="120px">
            <TextField
              select
              value={language}
              onChange={handleLanguageChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM14.665 8C14.1428 5.03217 13.0819 3 12 3C10.9181 3 9.85717 5.03217 9.33498 8H14.665ZM3.93552 8H8.31375C8.65216 5.96286 9.24889 4.27723 10.028 3.21671C7.35208 3.81488 5.1259 5.60466 3.93552 8ZM8.1719 15H3.51212C3.18046 14.0617 3 13.0519 3 12C3 10.9481 3.18046 9.93834 3.51212 9H8.1719C8.05949 9.94765 8 10.9555 8 12C8 13.0445 8.05949 14.0524 8.1719 15ZM10.028 20.7833C7.35208 20.1851 5.1259 18.3953 3.93552 16H8.31375C8.65216 18.0371 9.24889 19.7228 10.028 20.7833ZM20.4879 15C20.8195 14.0617 21 13.0519 21 12C21 10.9481 20.8195 9.93834 20.4879 9H15.8281C15.9405 9.94765 16 10.9555 16 12C16 13.0445 15.9405 14.0524 15.8281 15H20.4879ZM15.6862 16H20.0645C18.8741 18.3953 16.6479 20.1851 13.972 20.7833C14.7511 19.7228 15.3478 18.0371 15.6862 16ZM9.1844 15H14.8156C14.934 14.0624 15 13.0528 15 12C15 10.9472 14.934 9.93761 14.8156 9H9.1844C9.06598 9.93761 9 10.9472 9 12C9 13.0528 9.06598 14.0624 9.1844 15ZM12 21C10.9181 21 9.85717 18.9678 9.33498 16H14.665C14.1428 18.9678 13.0819 21 12 21ZM13.972 3.21671C16.6479 3.81488 18.8741 5.60466 20.0645 8H15.6862C15.3478 5.96286 14.7511 4.27723 13.972 3.21671Z"
                        fill="#2B3674"
                      />
                    </svg>
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: "64px",
                boxShadow: "none",
                bgcolor: "#FFFFFF",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
            >
              <MenuItem value="hu" sx={{ bgcolor: "#FFFFFF", width: "120px" }}>
                HU
              </MenuItem>
              <MenuItem value="en" sx={{ bgcolor: "#FFFFFF", width: "120px" }}>
                EN
              </MenuItem>
            </TextField>
          </Box>
          <Button
            onClick={handleToggleMenu}
            sx={{
              bgcolor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="50" height="50" rx="25" fill="white" />
              <path
                d="M25.0002 24.0571L30.5288 18.5285C30.7891 18.2682 31.2112 18.2682 31.4716 18.5285C31.7319 18.7889 31.7319 19.211 31.4716 19.4713L25.943 24.9999L31.4716 30.5285C31.7319 30.7889 31.7319 31.211 31.4716 31.4713C31.2112 31.7317 30.7891 31.7317 30.5288 31.4713L25.0002 25.9427L19.4716 31.4713C19.2112 31.7317 18.7891 31.7317 18.5288 31.4713C18.2684 31.211 18.2684 30.7889 18.5288 30.5285L24.0574 24.9999L18.5288 19.4713C18.2684 19.211 18.2684 18.7889 18.5288 18.5285C18.7891 18.2682 19.2112 18.2682 19.4716 18.5285L25.0002 24.0571Z"
                fill="#2B3674"
              />
            </svg>
          </Button>
        </Stack>
        <Stack spacing={2} direction={"column"} sx={{ pl: 4 }}>
          <Link
            href="#howitworks"
            onClick={handleToggleMenu}
            sx={{ color: "#FFFFFF" }}
          >
            Hogyan működik
          </Link>
          <Link
            href="#whoisitfor"
            onClick={handleToggleMenu}
            sx={{ color: "#FFFFFF" }}
          >
            Kinek ájánljuk
          </Link>
          <Link
            href="#prices"
            onClick={handleToggleMenu}
            sx={{ color: "#FFFFFF" }}
          >
            Árak
          </Link>
        </Stack>
        <Stack direction={"column"} spacing={1} sx={{ mt: 16 }}>
          <Button
            variant={"outlined"}
            sx={{
              py: 2,
              px: 4,
              color: "#FFFFFF",
              borderColor: "#FFFFFF",
              border: 2,
            }}
          >
            Belépés
          </Button>
          <Button variant={"contained"} sx={{ py: 2, px: 4 }}>
            Regisztráció
          </Button>
        </Stack>
      </Menu>
    </>
  );
};
