import React from "react";
import { Button } from "@mui/material";

export const RegistrationButton = (props) => {
  return (
    <Button
	  onClick={props.onClick}
      variant="contained"
      sx={{
        mt: 4,
        bgcolor: props.bColor,
        lineHeight: "32px",
        width: "100%",
      }}
    >
      {props.text}
    </Button>
  );
};
