import { Box, Grid, Button } from "@mui/material";
import { LoginTopMenu } from "../Navigation/LoginNavigation/LoginTopMenu";
import registrationbg from "../Assets/Registration-bg.png";
import { RegStep1 } from "./RegStep1";
import { RegStep2 } from "./RegStep2";
import { ValidationStep1 } from "../ValidationComponents/ValidationStep1";
import { RegStep4 } from "./RegStep4";
import { ValidationStep2 } from "../ValidationComponents/ValidationStep2";

import React, { useEffect, useReducer, useState, Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import { useNavigate,useLocation } from "react-router-dom";

const Registration = ({globalEvent,setGlobalEvent,title,cStep}) => {
  
  const navigate = useNavigate();
  const location = useLocation();
   
  const { t, i18n } = useTranslation();

  useEffect(() => {
	
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	console.log('------ Global Event ------ Registration');
	console.log(globalEvent);
	console.log('------------');
	}
	
	if (globalEvent?.type == 'showRegStep1') {
		navigate("/registration/");;
	}

	if (globalEvent?.type == 'showRegStep2') {
		setStep(2);
		navigate("/registration/email");
	}

	if (globalEvent?.type == 'showRegStep3') {
		navigate("/registration/verify", { replace: true }); 
	}

	if (globalEvent?.type == 'showRegStep4') {
		navigate("/registration/consent", { replace: true });
	}

	if (globalEvent?.type == 'responseRegisterError'){
	
	setStep(2);
	navigate("/registration/email");
	setGlobalEvent({type:'showRegisterError',data:globalEvent?.data});
	}


  }, [globalEvent])

  useEffect(() => {
        setStep(cStep);
    }, [cStep])


  const goHome = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'goHome',data:data})
  };

  const showToast = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showToast',data:data})
  };

  const showConfirm = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showConfirm',data:data})
  };

  const showMessage = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'showMessage',data:data})
  };

  
  
  const [step, setStep] = useState(cStep);

  const handleSetStep = () => {
    const newStep: number = step + 1;
    setStep(newStep);
  };

  return (
  
  <>
  {/*
	<Grid container>
		<Grid item xs={6}>
			<div>R E G I S T R A T I O N</div>
	
	<Button variant={"text"} onClick={(e)=>{goHome(e,{})}}>Go Home</Button>
	
	<Button variant={"text"} onClick={(e)=>{showToast(e,{'message':'teszt toast from reg'})}}>Show Toast</Button>
	
	<Button variant={"text"} onClick={(e)=>{showMessage(e,{'message':'teszt message from reg'})}}>Show Message</Button>
	
	<Button variant={"text"} onClick={(e)=>{showConfirm(e,{'message':'teszt confirm from reg'})}}>Show Confirm</Button>
	
	
			
		</Grid>
		<Grid item xs={6}>
			
		</Grid>
 	</Grid>
  */}
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid container sx={{}}>
        <Grid item md={6} sx={{ width: "50%" }}>
          <LoginTopMenu />
          {step === 1 ? (
            <RegStep1 globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          ) : step === 2 ? (
            <RegStep2 globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          ) : step === 3 ? (
            <ValidationStep1 globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          ) : step === 4 ? (
            <RegStep4 globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          ) : (
            <ValidationStep2
              globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}
			  title="A regisztráció sikeres!"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et"
              globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}
            />
          )}
          {/*
		  <Button
            onClick={handleSetStep}
            variant="outlined"
            sx={{ mt: 6, ml: 30 }}
          >
            Next Step
          </Button>
          */}
		</Grid>
        <Grid item md={6} sx={{ width: "50%" }}>
          <Box
            component="img"
            alt="background"
            src={registrationbg}
            sx={{
              top: 0,
              width: "100%",
              height: "100%",
            }}
          ></Box>
        </Grid>
      </Grid>
    </Box>
  </>
  
  );
	
};


export default Registration;