import { Paper, Grid, Box, Typography, Link, Stack } from "@mui/material";
import youtube from "../Assets/youtube-footer-logo.png";
import instagram from "../Assets/Instagram-footer-logo.png";
import facebook from "../Assets/facebook-footer-logo.png";
import linkedin from "../Assets/LinkedIN-footer-logo.png";

export const Footer = () => {
  return (
    <Paper
      sx={{
        mt: 8,
        bottom: 0,
        width: "100%",
        bgcolor: "primary.dark",
        borderRadius: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          py: 10,
          pl: { xs: 6, md: 0 },
          height: { xs: "464.48px", md: "272px" },
          width: { xs: "100%", md: "1104px" },
        }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            order: { xs: 3, sm: 1 },
            pl: { sm: 2, xl: 0 },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, color: "#FFFFFF" }}
          >
            © Copyright 2023 B120.eu
          </Typography>
          <Typography variant="caption" color={"#FFFFFF"} fontWeight={500}>
            Minden jog fenntartva.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ display: { xs: "none", sm: "block" }, order: { xs: 0, sm: 2 } }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            order: { xs: 1, sm: 3 },
            display: "flex",
            mb: { sm: 0, xs: 1 },
            pr: { xs: 0, sm: 5 },
            justifyContent: { xs: "left", sm: "end" },
          }}
        >
          <Box>
            <Link>Impresszum</Link>
            <br />
            <Link>Felhasználási feltételek</Link>
            <br />
            <Link>Adatvédelmi nyilatkozat</Link>
            <br />
            <Link>Süti szabályzat</Link>
            <br />
            <Link>Kapcsolat</Link>
            <br />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            order: { xs: 2, sm: 4 },
            mb: { sm: 0, xs: 6 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              justifyContent: { xs: "left", sm: "end" },
              pr: { sm: 2, xl: 0 },
            }}
          >
            <Box component="img" alt="background" src={youtube}></Box>
            <Box component="img" alt="background" src={instagram}></Box>
            <Box component="img" alt="background" src={facebook}></Box>
            <Box component="img" alt="background" src={linkedin}></Box>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
